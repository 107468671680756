export default `
<div class="carousel-track" data-glide-el="track">
    <div class="carousel-slides">
        <div data-year="2024">
            <a class="carousel-cta" href="https://player.vimeo.com/video/966849185" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails_cubhouse/2024_Amplify.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">2024</strong>
                        <span class="subtitle">Amplify <br> with Spotify</span>
                    </span>
                </span>
            </a>
        </div>
        
        <div data-year="2023">
            <a class="carousel-cta" href="https://player.vimeo.com/video/836498895" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails_cubhouse/2023_For_All_Life.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">2023</strong>
                        <span class="subtitle">For All Life <br> with Volvo Cars</span>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2022">
            <a class="carousel-cta" href="https://player.vimeo.com/video/787891874" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails_cubhouse/2022_Further_Together.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">2022</strong>
                        <span class="subtitle">Further Together <br> with Gymshark</span>
                    </span>
                </span>
            </a>
        </div>


        <div data-year="2021">
            <a class="carousel-cta" href="https://player.vimeo.com/video/582440081" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails_cubhouse/2021_Rebuild_the_World.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">2021</strong>
                        <span class="subtitle">Rebuild the World <br> with The LEGO Group</span>
                    </span>
                </span>
            </a>
        </div>
    </div>
</div>
`;
