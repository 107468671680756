import React, { useState, useEffect } from 'react';
import s from './Brief.module.scss';
import cn from 'classnames';
import Video from './../video/Video';
import BriefImageWebp from '../../assets/2023/brief/Brief-Both-v2.webp';
// import BriefImagePng from '../../assets/2023/brief/Brief-Both-v2.png';
import BriefImageMobileWebp from '../../assets/2023/brief/Brief-Mobile-v2.webp';
// import BriefImageMobilePng from '../../assets/2023/brief/Brief-Mobile-v2.webp';
import VideoPosterImage from '../../assets/2024/brief/VIDEO_COVER-min.png';
import SoundWaveLottie from "../../assets/2023/brief/soundwave-lottie.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { isMobile } from 'react-device-detect';

interface PartnerBriefPropsType {
    inView: boolean
}

const PartnerBrief = ({ inView }: PartnerBriefPropsType) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
    const [videoPlaying, setVideoPlaying] = useState(false);
    return (
        <div className={s.pageCentered}>
            <div className={cn(s.pageFullWidth, s.pageBrief)}>
                <div className={s.briefContainer}>
                    <div className={s.contentWrapper}>
                        <div className={s.briefHeader}>
                            <div className={s.briefHeaderText}>THE BRIEF</div>
                        </div>
                        <div className={s.briefDescription}>
                            <p>
                                Connect an audience to a product or service from a brand you choose in a way that wasn’t possible three <span className={s.nowrap}>years ago.</span>
                            </p>
                            {/* <p>
                      In 2023-, we are asking young people to protect what is most important about our home planet – life itself. How can we pioneer the use of technology to protect people and make them <span className={s.nowrap}>feel safe?</span>
                      </p> */}
                        </div>

                    </div>
                    {/* <div className={s.heroWrapper}> */}
                    {/* <picture className={s.heroImage}> */}
                    {/* <source srcSet={isDesktop ? BriefImageWebp : BriefImageMobileWebp} type={'image/webp'} /> */}
                    {/* <img src={isDesktop ? BriefImagePng : BriefImageMobilePng} alt={''} style={{width: '100%', height: "100%"}}/> */}
                    {/* </picture> */}
                    {/* <div className={s.videoWrapper}> */}
                    {/* <Video src='https://vimeo.com/920539723?share=copy' setVideoPlaying={setVideoPlaying} poster={VideoPosterImage} /> */}
                    {/* </div> */}
                    {/* </div> */}
                    <div className={s.briefCta}>
                        <a
                            className={s.ctaButton}
                            href='/media/Future_Lions_The_Lion_Within_2025.pdf'
                            download
                        >
                            <span className={s.ctaText}>
                                DOWNLOAD THE FULL BRIEF
                            </span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PartnerBrief;
