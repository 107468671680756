import React, { ReactHTMLElement } from 'react';
import carousel_elements from './elements';

import Glide from '../../elements/glide';
import Swipe from '../../elements/glide/components/swipe';
import Html from '../../elements/glide/components/html';
import Translate from '../../elements/glide/components/translate';
import Transition from '../../elements/glide/components/transition';
import Direction from '../../elements/glide/components/direction';
import Breakpoints from '../../elements/glide/components/breakpoints';
import Peek from '../../elements/glide/components/peek';
import Gaps from '../../elements/glide/components/gaps';
import Move from '../../elements/glide/components/move';
import Clones from '../../elements/glide/components/clones';
import Resize from '../../elements/glide/components/resize';
import Build from '../../elements/glide/components/build_cubhouse';
import Run from '../../elements/glide/components/run_cubhouse';
import Autoplay from '../../elements/glide/components/autoplay';
import Sizes from '../../elements/glide/components/sizes';
import { Tabs } from '../tabs/Tabs';
// import {Player} from "@lottiefiles/react-lottie-player";
// import calcHeroAnimation from '../../helpers/calcHeroAnimation';
import s from "../brief/Brief.module.scss";
import cn from "classnames";

type CubhouseProps = { inView: boolean, scrollPos: number, isParallax: boolean } & ReactHTMLElement<HTMLDivElement>['props'];

export class Cubhouse extends React.Component<CubhouseProps, any> {
    state = {
        heroAnimationCss: {},
        prevHeroAnimationCss: {}
    };
    carouselWrapper: HTMLElement = null;
    glideCarousel: any = null;
    leafLottieRef = React.createRef<HTMLDivElement>();
    pigeonsLottieRef = React.createRef<HTMLDivElement>();
    heroImageRef = React.createRef<HTMLImageElement>();
    yearToSlideMap = {
        '2024': 0,
        '2023': 1 + 0,
        '2022': 1 + 1,
        '2021': 1 + 2,
    };
    tabsDef = [
        { label: '2024' },
        { label: '2023' },
        { label: '2022' },
        { label: '2021' },
    ];

    componentDidMount() {
        this.carouselWrapper.innerHTML = carousel_elements;
        this.glideCarousel = new Glide(this.carouselWrapper, {
            type: 'carousel',
            perView: 4,
            focusAt: 0,
            startAt: 0,
            peek: 0,
            gap: 10,
            animationDuration: 800,
            swipeThreshold: 40,
            autoplay: false,
            hoverpause: false,
            dragThreshold: false,
            breakpoints: {
                1023: {
                    perView: 3,
                    peek: 0,
                    startAt: 0,
                    focusAt: 0,
                    animationDuration: 800
                },
                500: {
                    perView: 1,
                    gap: 15,
                }
            }
        });
        const mountSet = {
            Html,
            Translate,
            Transition,
            Direction,
            Peek,
            Gaps,
            Sizes,
            Move,
            Clones,
            Resize,
            Build,
            Run,
            Swipe,
            Autoplay,
            Breakpoints
        };
        this.glideCarousel.mount(mountSet);

        if (window.innerWidth <= 1024) {
            this.glideCarousel.go('=0');
            this.glideCarousel.hasBeenActive = false;
        }

        window.addEventListener('resize', this.handleWindowChange);
    }

    isMobile() {
        const windowWidth =
            typeof window !== 'undefined' ? window.innerWidth : 0;
        return windowWidth <= 600;
    }

    componentWillUnmount(): void {
        this.glideCarousel.destroy();
    }

    goToYear(label: string) {
        const id = this.yearToSlideMap[label];
        this.glideCarousel.go(`=${id}`);
    }

    tabClick = (tab) => {
        this.goToYear(tab.label);
    };
    goLeft = () => {
        this.glideCarousel.go(`<`);
    };
    goRight = () => {
        this.glideCarousel.go(`>`);
    };
    checkIfDisabled = () => {
        const disabled = this.glideCarousel.disabled;
        return disabled
    }

    render() {
        return (
            <div className={s.pageCentered}>
                <div className={cn(s.pageFullWidth, s.pageCubhouse)}>
                    <div className={s.cubhouseContainer}>
                        <div className='cubhouse-wrapper'>
                            <div className='cubhouse-header'>

                                <div className='cubhouse-header-text'>INSIDE THE CUB HOUSE</div>
                                <div className='cubhouse-subheading'>
                                    Gain jury insights and see past <span className='nowrap'>award ceremonies</span>
                                </div>
                            </div>

                            <div className='cubhouse-nav'>
                                <Tabs tabsDef={this.tabsDef} onClick={this.tabClick}
                                    isDisabled={this.checkIfDisabled} />
                            </div>

                            <div className='carousel-block'>
                                <div
                                    className='carousel-wrapper'
                                    ref={(ref) => (this.carouselWrapper = ref)}
                                />
                                {window.innerWidth < 1024 && (
                                    <>
                                        <div className='cubhouse-left'>
                                            <button
                                                className='cubhouse-left-cta'
                                                type='button'
                                                onClick={this.goLeft}
                                            >
                                                <span className='cubhouse-cta-icon'></span>
                                            </button>
                                        </div>
                                        <div className='cubhouse-right'>
                                            <button
                                                className='cubhouse-right-cta'
                                                type='button'
                                                onClick={this.goRight}
                                            >
                                                <span className='cubhouse-cta-icon'></span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='fl-link'>
                                <a href='https://www.akqa.com/future-lions/' target='_blank'>Learn more about Future Lions {' '}
                                    <span className={`learn-more-arrow arrow-${this.isMobile() ? 'mobile' : 'desktop'}`}></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
