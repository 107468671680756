import React, { ReactHTMLElement } from 'react';
import s from './Brief.module.scss';
import { Link } from 'react-router-dom';

import { Alumni } from '../alumni/Alumni';
import { CookieBanner } from '../cookie-banner/CookieBanner';
import { Faq } from '../faq/Faq';
import Logos from '../logos/Logos';
import { FakeScroller } from '../../elements/fake-scroller/FakeScroller';
import { Bulletpoints } from '../../elements/bulletpoints/Bulletpoints';
import cn from 'classnames';
import { GlobalContext } from './../../providers/GlobalProvider';
// import FLHeaderLogo from './../../assets/2025/logos/FL_Header.svg';
// import { Icon } from '../../elements/icon/Icon';


import Home from './Home';
import PartnerBrief from './PartnerBrief';
import Milestone from './Milestone';
import Winners from './Winners';
import Awards from './Awards';
import SchoolOfTheYear from './SchoolOfTheYear';
import { Icon } from '../../elements/icon/Icon';
import Countdown from './Countdown';
import { Cubhouse } from '../cubhouse/Cubhouse';
// import WhiteLionIcon from './../../assets/2022/main/Default-FL-Logo_199x217.svg';

type BriefProps = {} & ReactHTMLElement<HTMLDivElement>['props'];

export class Brief extends React.Component<BriefProps, any> {
    targetDate = new Date("February 3, 2025 09:00:00 GMT+0000").getTime();
    currentDate = Date.now();
    textNodeRefs: any[] = [];
    breakpoints: number[] = []
    // breakpoints: number[] = [0, 28, 56, 84, 112, 140, 168] // for 7 pages
    // breakpoints: number[] = [0, 28, 56, 84, 112]
    height: number = 10000;
    frames: number = 0;
    state = {
        scrollPosition: 0,
        currentPage: 0,
        lionAnimationProgress: 0,
        clawAnimationProgress: 0,
        earAnimationProgress: 0,
        clawAnimationStyle: {},
        earAnimationStyle: {},
        stillImageStyle: {},
        pages: [],
        isDesktop: this.isDesktop(),
        isMobile: this.isMobile(),
        applyParallax: this.applyParallax()
    };
    static defaultProps = {};
    static contextType = GlobalContext;

    currPage = this.context;

    scrollerRef: any = null;

    heroImageRef: any = null;

    constructor(props: BriefProps) {
        super(props);

        if (this.targetDate - this.currentDate > 0) {
            this.breakpoints = [];
            this.frames = 0;
        } else {
            this.breakpoints = [0, 28, 56, 84, 112, 140, 168];
            // this.frames = 165; // for 6 pages
            this.frames = 196;
        }
    }

    componentDidMount(): void {
        this.setState({
            pages: this.getPages()
        });
        window.addEventListener('resize', this.handleWindowChange);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleWindowChange);
    }

    isDesktop() {
        const windowWidth =
            typeof window !== 'undefined' ? window.innerWidth : 0;
        return windowWidth > 1024;
    }

    isMobile() {
        const windowWidth =
            typeof window !== 'undefined' ? window.innerWidth : 0;
        return windowWidth <= 600;
    }

    applyParallax() {
        const windowWidth =
            typeof window !== 'undefined' ? window.innerWidth : 0;
        const windowHeight =
            typeof window !== 'undefined' ? window.innerHeight : 0;
        return windowWidth <= 1248 && windowHeight >= windowWidth * 1.1;
    }

    isTablet() {
        const windowWidth =
            typeof window !== 'undefined' ? window.innerWidth : 0;
        return windowWidth > 600;
    }

    handleWindowChange = () => {
        const isDesktop = this.isDesktop();
        if (this.state.isDesktop !== isDesktop) {
            this.setState({
                isDesktop: isDesktop
            });
        }
        this.setState({
            applyParallax: this.applyParallax()
        });
    };

    getPages() {
        if (this.targetDate - this.currentDate > 0) {
            return [
                (inView, scrollPos, isParallax) => {
                    return <Countdown scrollPos={scrollPos} isParallax={false} inView={inView}
                        onExpire={() => {
                            window.location.reload();
                        }}
                        targetDate={this.targetDate}
                    />
                }
            ];
        } else {
            return [
                (inView, scrollPos, isParallax) => { return <Home scrollPos={scrollPos} isParallax={false} inView={inView} /> },
                // (inView) => { return <PartnerBrief inView={inView} /> },
                (inView) => { return <Milestone inView={inView} /> },
                (inView) => { return <PartnerBrief inView={inView} /> },
                (inView) => { return <SchoolOfTheYear inView={inView} /> },
                // (inView) => { return <Winners inView={inView} /> },
                // (inView) => { return <Awards inView={inView} /> },
                (inView, scrollPos, isParallax) => { return <Alumni scrollPos={scrollPos} isParallax={false} inView={inView} /> },
                (inView, scrollPos, isParallax) => { return <Cubhouse scrollPos={scrollPos} isParallax={false} inView={inView} /> },
                (inView) => { return <Faq inView={inView} /> }
            ];
        }
    }

    onLionClick() {
        if (this.state.currentPage < 1) {
            this.onBulletpointClick(1);
        }
    }

    onBulletpointClick(index) {
        if (index === this.state.currentPage) return;

        let target;
        if (index === 0) {
            this.scrollerRef.scrollTo(0);
            return;
        }
        if (index === this.state.pages.length - 1) {
            this.scrollerRef.scrollTo(this.height);
            return;
        }

        target =
            this.breakpoints[index] +
            (this.breakpoints[index + 1] - this.breakpoints[index]) / 2;
        const topPosition = ~~((this.height * target) / this.frames);
        this.scrollerRef.scrollTo(topPosition);
    }

    slideNumber() {
        return this.breakpoints.length;
    }

    slideHeight() {
        return this.frames / this.slideNumber();
    }

    onFakeScroll(position) {
        this.onScrollPositionChange(position);
    }

    onScrollPositionChange(position) {
        const targetPosition = ~~(position * this.frames);
        const breakpoint = this.breakpoints.find((breakpoint, index) => {
            if (!this.breakpoints[index + 1]) {
                return this.breakpoints[index];
            }
            return (
                targetPosition >= breakpoint &&
                targetPosition < this.breakpoints[index + 1]
            );
        });

        const breakpointIndex = breakpoint
            ? this.breakpoints.indexOf(breakpoint)
            : 0;

        this.context.setCurrPage(breakpointIndex);

        this.setState({
            currentPage: breakpointIndex,
            scrollPosition: position
        });
    }

    getAnimationStyle(index) {
        // if (this.state.currentPage !== index ||
        //     (index === 0 && this.state.applyParallax)) return;

        const animationHeight = 23;
        const fadeIn = 14;
        const fadeOut = 5;

        const min = this.breakpoints[index] / this.frames;
        const max = (this.breakpoints[index + 1] || this.frames) / this.frames;
        const distance = max - min;
        const interpolated = (this.state.scrollPosition - min) / distance;

        let transition = -~~((interpolated * 2 - 1) * animationHeight);
        let opacity = 1;

        if (min === 0) {
            return {
                transform: `translateY(-${(
                    animationHeight * interpolated
                ).toFixed(2)}px)`,
                opacity: 1 - interpolated
            };
        }

        if (transition > 0) {
            const delta = transition - (animationHeight - fadeIn);
            opacity = delta > 0 ? (fadeIn - delta) / fadeIn : 1;
        } else {
            const delta = transition + (animationHeight - fadeOut);
            opacity = delta < 0 ? (fadeOut - Math.abs(delta)) / fadeOut : 1;

            if (max === 1) {
                return {
                    transform: `none`,
                    opacity: 1
                };
            }
        }

        return {
            // transform: (index === 3 && this.state.applyParallax) ? '' : `translateY(${transition}px)`,
            transform: `translateY(${transition}px)`,
            opacity: opacity
        };
    }

    render() {
        return (
            <div className={s.brief}>
                {/* {(
                    (this.state.currentPage !== 0 && window.innerWidth > 600) ||
                    (this.state.currentPage === 0 && window.innerWidth <= 600)
                )
                && (
                    <div className={s.desktopHeaderWrapper}>
                        <div className={s.flTitle}>FUTURE LIONS <br /> 2025</div>
                    </div>
                )} */}
                {/* {(this.state.currentPage !== 0 && window.innerWidth > 1248) && (
                    <div className={s.headerBarWrapper}>
                        <div className={s.headerBar}>
                            <div className={s.headerText1}>AMPLIFY</div>
                            <div className={s.headerText2}>BE HEARD</div>
                        </div>
                    </div>
                )} */}
                <div className={cn(s.desktopHeaderWrapper, s.fadeOut, {
                    [s.hidden]: !(
                        (this.state.currentPage !== 0 && this.isTablet())
                    )
                })}>
                    {/* <div className={s.flTitle}>FUTURE LIONS <br /> 2025</div> */}
                    <img src='/media/2025/FL_Header.svg' alt={'Header Logo'} />
                </div>
                {/* <div className={cn(s.headerBarWrapper, s.fadeOut, {
                    [s.hidden]: !(this.state.currentPage !== 0 && window.innerWidth > 1248)
                })}>
                    <div className={s.headerBar}>
                        <div className={s.headerText1}>AMPLIFY</div>
                        <div className={s.headerText2}>BE HEARD</div>
                    </div>
                </div> */}
                {/* <div
                    onClick={(e) => this.onLionClick()}
                    className={s.lionLogo}
                >
                    {this.state.currentPage === 0 && (
                        <a
                            href='https://entries.futurelions.com/sign-in'
                        >
                            <div className={cn(s.lionText)}>
                                Start
                            </div>
                            <div className={s.lionBackground}/>
                            <Icon
                                className={s.logoIcon}
                                icon={<WhiteLionIcon />}
                                alt='Lion Logo'
                            />

                        </a>
                    )}
                </div> */}

                <CookieBanner />
                {this.targetDate - this.currentDate < 0 && (
                    <div className={s.bulletpointsContainer}>
                        <Bulletpoints
                            pages={this.state.pages.length}
                            currentPage={this.state.currentPage}
                            onBulletpointClick={(index) =>
                                this.onBulletpointClick(index)
                            }
                        />
                    </div>
                )}


                <FakeScroller
                    isMobile={this.isMobile()}
                    pageId={this.state.currentPage}
                    height={this.height}
                    ref={(ref) => (this.scrollerRef = ref)}
                    onNormalizedScrollPositionChange={(position) =>
                        this.onFakeScroll(position)
                    }
                >

                    {this.state.pages.map((page, index) => {
                        return (
                            <>
                                {(index === 0 && this.isMobile()) && (
                                    <div style={this.getAnimationStyle(index)} className={cn(s.desktopHeaderWrapper)}>
                                        {/* <div className={s.flTitle}>FUTURE LIONS <br /> 2025</div> */}
                                        {/* <img src='/media/2025/FL_Header.svg' alt={'Header Logo'} /> */}
                                    </div>
                                )}
                                <div
                                    style={this.getAnimationStyle(index)}
                                    key={index}
                                    className={cn(s.pageWrapper, {
                                        [s.active]: this.state.currentPage === index,
                                        // [s.wrapperDates]: index === 3
                                    })}
                                >
                                    {index === 0 || index === 5 ?
                                        page(this.state.currentPage === index, this.state.scrollPosition, this.state.applyParallax) :
                                        page(this.state.currentPage === index)}
                                </div>
                            </>
                        );
                    })}
                </FakeScroller>
                {/* {(this.state.currentPage === 0 ||
                    (this.state.currentPage === 5 && window.innerHeight > 600)) && (
                        <div className={s.desktopLogosWrapper}>
                            <div className={s.footerText}>
                                <div className="spotify">SPOTIFY</div>
                                <div className="wsj">THE WALL STREET JOURNAL</div>
                                <div className="akqa">AKQA</div>
                                <div className="cannes">CANNES LIONS</div>
                            </div>
                        </div>
                    )} */}
                {(
                    // this.state.currentPage === 0 ||
                    (this.state.currentPage === this.breakpoints.length - 1))
                    && (
                        <div className={s.desktopLogosWrapper}>
                            <Logos pageId={this.state.currentPage} breakpointsCount={this.breakpoints.length} />
                        </div>
                    )}
                {(this.state.currentPage === this.breakpoints.length - 1) && (
                    <div className={cn(s.termsAndConditions)}>
                        <a href={'https://www.akqa.com/privacy-policy/'} target={'_blank'} rel='noopener noreferrer'>Privacy policy</a>
                        <Link to={'/terms-and-conditions'}>
                            Terms and conditions
                        </Link>
                    </div>
                )}

                {this.targetDate - this.currentDate < 0 && (
                    <>
                        {(this.state.currentPage == 0) && (
                            <div className={cn(s.downArrowWrapper)}><div className={cn(s.downArrow)}></div></div>
                        )}
                    </>
                )}
                {/* For smaller screen -> page 5 and for larger screen -> all pages excluding 0 */}
                {/* {((this.state.currentPage === 5 && window.innerWidth < 600) || (this.state.currentPage !== 0 && window.innerWidth >= 600)) && (
                    <div className={s.desktopLogosWrapper}>
                        <div className={s.footerText}>
                            <a target={'_blank'} href='https://www.spotify.com/' className="spotify">SPOTIFY</a>
                            <a target={'_blank'} href='https://www.wsj.com/' className="wsj">THE WALL STREET JOURNAL</a>
                            <a target={'_blank'} href='https://www.akqa.com/' className="akqa">AKQA</a>
                            <a target={'_blank'} href='https://www.canneslions.com/' className="cannes">CANNES LIONS</a>
                        </div>

                    </div>
                )} */}
            </div>
        );
    }
}
