import React, { ReactHTMLElement } from 'react';
import stl from './Bulletpoints.module.scss';
import cn from 'classnames';


type BulletpointsProps = {
    currentPage: number;
    pages: number;
    onBulletpointClick?: (index: number) => void;
} & ReactHTMLElement<any>['props'];

export class Bulletpoints extends React.Component<BulletpointsProps, any> {
    static defaultProps = {
        currentPage: 0,
        pages: 1
    };

    scrollerRef;

    onBulletpointClick(index) {
        if (this.props.onBulletpointClick) {
            this.props.onBulletpointClick(index);
        }
    }

    getIndicatorStyle() {
        return {
            transform: `translate(-50%, ${100 * this.props.currentPage}%)`
        };
    }

    copy() {
        return [
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >

                <div>HOME</div>
            </div>,
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >
                <div>KEY DATES</div>
            </div>,
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >
                <div>THE BRIEF</div>
            </div>,
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >
                <div>SCHOOL OF THE YEAR</div>
            </div>,
            // <div
            //     className={cn(stl.bulletLabel, {
            //         [stl.bulletLabelLight]: this.props.currentPage === 0
            //     })}
            // >
            //     <div>THE WINNERS</div>
            // </div>,
            // <div
            //     className={cn(stl.bulletLabel, {
            //         [stl.bulletLabelLight]: this.props.currentPage === 0
            //     })}
            // >
            //     <div>AWARD CEREMONY</div>
            // </div>,
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >
                <div>ALUMNI</div>
            </div>,
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >
                <div>CUB HOUSE</div>
            </div>,
            <div
                className={cn(stl.bulletLabel, {
                    [stl.bulletLabelLight]: this.props.currentPage === 0
                })}
            >
                <div>FAQ</div>
            </div>
        ];
    }

    render() {
        return (
            <div className={stl.bulletpoints}>
                {/* <div
                    className={stl.bulletpointIndicator}
                    style={this.getIndicatorStyle()}
                /> */}
                {new Array(this.props.pages)
                    .fill(undefined)
                    .map((page, index) => {
                        return (
                            <div
                                key={index}
                                onClick={(e) => this.onBulletpointClick(index)}
                                className={cn(
                                    stl.bulletpointWrapper,
                                    this.props.currentPage === index &&
                                    stl.activeBullet
                                )}
                            >
                                <div className={cn(stl.bulletpoint)} ><img className={cn(stl.bulletImage)} src="/media/2024/CUB.svg" alt="" /></div>
                                {this.copy()[index]}
                            </div>
                        );
                    })}
            </div>
        );
    }
}
